.App {
  text-align: center;
}


html {
  height: 100%;
}

#root {
  min-height: 100vh;
}

body {
  min-height: 100vh;

}

.fullPage {
  min-height: 100vh;
  /* background-color: #282c34; */
}

.App-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}


@media (min-width: 576px) {
  .App-container {
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    /* gap:30px; */
  }
}




#themeChange {
  height: 50px;
}


.App-timer{
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  max-width: 40vw;
}




/* .App-todo {
  flex-shrink: 2;
} */
