.time-bar div {
    /* --durationTime:0; */
    height: 5px;
    background: linear-gradient(to bottom, red, #900);
    /* animation: roundtime calc(var(--durationTime) * 1s) linear forwards; */
    /* animation: resettime 1s linear forwards; */
}

@keyframes roundtime {
    to {
        transform: scaleX(0);
    }
}

@keyframes resettime {
    to {
        transform: scaleX(1);
    }
}

.quote {
    max-width:300px;
}

.setTime{
    max-width:100px;
}